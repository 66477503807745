import { Check } from "@mui/icons-material";
import { Grid } from '@mui/material';
import React from 'react';
import {
  ADD_ON_MIRCOACTION_OPEN_KEY,
  BOX_ID_CABINS,
  BOX_ID_CONTACTDATA, BOX_ID_PAYBACKS,
  BOX_ID_TICKETADDONS,
  BOX_ID_TRAINCONNECTION,
  BOX_ID_VEHICLES,
  CABINREBOOK_OPEN_KEY,
  CANCELATION_OPEN_KEY,
  DASHBOARDAPP_EDIT_ALL_TICKETS,
  DASHBOARDAPP_EDIT_COMMENT,
  DASHBOARDAPP_EDIT_NOTE, DASHBOARDAPP_EDIT_PAYBACKS,
  DASHBOARDAPP_EDIT_SERVICECENTERNOTE,
  DASHBOARDAPP_MICROACTION_ADDONS, DASHBOARDAPP_MICROACTION_CABINS,
  DASHBOARDAPP_MICROACTION_PERSONALDATA,
  DASHBOARDAPP_MICROACTION_TRAINCONNECTION,
  DASHBOARDAPP_MICROACTION_VEHICLES,
  DASHBOARDAPP_VIEW_COMMENT, DASHBOARDAPP_VIEW_HISTORY,
  DASHBOARDAPP_VIEW_NOTE, DASHBOARDAPP_VIEW_PAYBACKS,
  DASHBOARDAPP_VIEW_SERVICECENTERNOTE,
  PERSONALDATA_MIRCOACTION_OPEN_KEY,
  VEHICLE_MIRCOACTION_OPEN_KEY,
} from '../../../constants';
import { usePermissions } from '../../Login/LoginContext';
import ServiceValues from '../../ServiceValues';
import ServiceValuesEdit from '../../ServiceValues/components/ServiceValuesEdit';
import { ServiceValuesContextWrapper } from '../../ServiceValues/ServiceValuesContext';

import AddOns from '../components/AddOns';
import AddOnsEdit from '../components/AddOnsEdit';
import CabinEdit from '../components/CabinEdit';
import Cabins from '../components/Cabins';
import CategoryBox from '../components/CategoryBox';
import Comment from '../components/Comment';
import Contact from '../components/Contact';
import Destinations from '../components/Destinations';
import History from '../components/History';
import Notes from '../components/Notes';
import PersonalDataEdit from '../components/PersonalDataEdit';
import ServiceCenterNotes from "../components/ServiceCenterNotes";
import Summary from '../components/Summary';
import TrainConnectionEdit from "../components/TrainConnectionEdit";
import Vehicles from '../components/Vehicles';
import VehiclesEdit from '../components/VehiclesEdit';
import PaybackEdit from '../components/PaybackEdit'
import Paybacks from '../components/Paybacks'

const DesktopView = ({ ticket, isRebooked, isToday, departureTime, arrivalTime, wakeUpTime }) => {
  const { canAccess } = usePermissions() || {}

  if (!ticket) {
    return null;
  }

  return (
    <Grid container spacing={2} sx={{paddingBottom: isToday ? 11 : 0}}>
      <Grid item xs={6}>
        <Grid container spacing={2} justifyContent={'flex-start'}>
          <Grid item xs={12}>
            <CategoryBox
              ticket={ticket}
              title={'Reservierung'}
              initialOpen
              boxID={BOX_ID_TRAINCONNECTION}
              dialogSize={'sm'}
              dialogContent={canAccess(DASHBOARDAPP_MICROACTION_TRAINCONNECTION) && <TrainConnectionEdit ticket={ticket} />}
            >
              <Destinations
                origin={ticket.DepartureStation}
                destination={ticket.ArrivalStation}
                departureTime={departureTime}
                arrivalTime={arrivalTime}
                isToday={isToday}
              />
              {ticket.CanView !== false && !isRebooked && (
                <Summary ticket={ticket} />
              )}
            </CategoryBox>
          </Grid>

          {ticket.CanView !== false && (
            <>
              <Grid item xs={12}>
                <CategoryBox
                  ticket={ticket}
                  title={'Extras (Ticket)'}
                  ticketActionKey={ADD_ON_MIRCOACTION_OPEN_KEY}
                  initialOpen
                  boxID={BOX_ID_TICKETADDONS}
                  dialogSize={'sm'}
                  dialogContent={canAccess(DASHBOARDAPP_MICROACTION_ADDONS) && <AddOnsEdit />}
                >
                  <AddOns ticket={ticket} />
                </CategoryBox>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2} justifyContent={'flex-start'}>
          {ticket.CanView ? (
            <>
              <Grid item xs={12}>
                <CategoryBox
                  ticket={ticket}
                  title={'Kontakt'}
                  initialOpen
                  boxID={BOX_ID_CONTACTDATA}
                  ticketActionKey={PERSONALDATA_MIRCOACTION_OPEN_KEY}
                  dialogSize={'sm'}
                  dialogContent={canAccess(DASHBOARDAPP_MICROACTION_PERSONALDATA) && <PersonalDataEdit customer={ticket?.Customer} />}
                >
                  <Contact ticket={ticket} />
                </CategoryBox>
              </Grid>

              {canAccess(DASHBOARDAPP_VIEW_PAYBACKS) && (
                <Grid item xs={12}>
                  <CategoryBox
                    ticket={ticket}
                    title={'Payback'}
                    initialOpen
                    boxID={BOX_ID_PAYBACKS}
                    dialogSize={'sm'}
                    dialogContent={canAccess(DASHBOARDAPP_EDIT_PAYBACKS) && <PaybackEdit ticketNumber={ticket?.TicketNumber} paybackValues={ticket?.PaybackValues || []} />}
                  >
                    <Paybacks currentReservationPrice={ticket?.CurrentReservationPrice?.Amount} paybackValues={ticket?.PaybackValues || []} />
                  </CategoryBox>
                </Grid>
              )}

              {canAccess(DASHBOARDAPP_VIEW_NOTE) && (
                <Grid item xs={12}>
                  <CategoryBox
                    ticket={ticket}title={'Zugbegleiter-Notiz'}>
                    <Notes
                      ticketNumber={ticket.TicketNumber || ''}
                      value={ticket.Note || ''}
                      readOnly={!canAccess(DASHBOARDAPP_EDIT_NOTE)}
                    />
                  </CategoryBox>
                </Grid>
                )}

              {canAccess(DASHBOARDAPP_VIEW_SERVICECENTERNOTE) && (
                <Grid item xs={12}>
                  <CategoryBox
                    ticket={ticket}title={'Kundenservice-Notiz'} initialOpen>
                    <ServiceCenterNotes
                      ticketNumber={ticket.TicketNumber || ''}
                      value={ticket.ServiceCenterNote || ''}
                      readOnly={!canAccess(DASHBOARDAPP_EDIT_SERVICECENTERNOTE)}
                    />
                  </CategoryBox>
                </Grid>
              )}

              {canAccess(DASHBOARDAPP_VIEW_COMMENT) && (
                <Grid item xs={12}>
                  <CategoryBox
                    ticket={ticket}title={'Zusätzliche Mitteilung'} initialOpen>
                    <Comment
                      ticketNumber={ticket.TicketNumber || ''}
                      value={ticket.Comment || ''}
                      readOnly={!canAccess(DASHBOARDAPP_EDIT_COMMENT)}
                    />
                  </CategoryBox>
                </Grid>
              )}
            </>
          ) : (
            <Grid item xs={12}>
              <CategoryBox
                ticket={ticket}title={'Fehler'} initialOpen>
                Zum Lesen dieses Tickets fehlen leider die Berechtigungen.
              </CategoryBox>
            </Grid>
          )}
        </Grid>
      </Grid>

      {ticket.CanView && (
        <Grid item xs={12}>
          <CategoryBox
            ticket={ticket}
            title={'Abteile'}
            ticketActionKey={CABINREBOOK_OPEN_KEY}
            initialOpen
            dialogSize={'sm'}
            dialogContent={canAccess(DASHBOARDAPP_MICROACTION_CABINS) && <CabinEdit ticket={ticket} boxID={BOX_ID_CABINS} />}
            chipContent={ticket.Checked ? `${ticket.Checked.format('HH:mm')} Uhr` : null}
            chipIcon={<Check />}
          >
            {ticket.CanView !== false && !isRebooked && (
              <Cabins
                ticket={ticket}
              />
            )}
          </CategoryBox>
        </Grid>
      )}

      {ticket.CanView && ticket?.VehicleSlots?.length > 0 && (
        <Grid item xs={12}>
          <CategoryBox
            ticket={ticket}
            title={'Fahrzeuge'}
            ticketActionKey={VEHICLE_MIRCOACTION_OPEN_KEY}
            initialOpen
            dialogSize={'sm'}
            dialogContent={canAccess(DASHBOARDAPP_MICROACTION_VEHICLES) && <VehiclesEdit ticket={ticket} boxID={BOX_ID_VEHICLES} />}
          >
            <Vehicles ticket={ticket} />
          </CategoryBox>
        </Grid>
      )}

      {ticket.CanView && canAccess(DASHBOARDAPP_EDIT_ALL_TICKETS) && (
        <Grid item xs={12}>
          <CategoryBox
            ticket={ticket}
            title={'Leistung / Zahlstatus'}
            ticketActionKey={CANCELATION_OPEN_KEY}
            dialogSize={'lg'}
            dialogContent={canAccess(DASHBOARDAPP_EDIT_ALL_TICKETS) && (
              <ServiceValuesEdit ticketNumber={ticket.TicketNumber}/>
            )}
          >
            <ServiceValuesContextWrapper>
              <ServiceValues
                ticketNumber={ticket.TicketNumber}
              />
            </ServiceValuesContextWrapper>
          </CategoryBox>
        </Grid>
      )}

      {ticket.CanView && canAccess(DASHBOARDAPP_VIEW_HISTORY) && (
        <Grid item xs={12}>
          <CategoryBox
            ticket={ticket}title={'Ticket Historie'}>
            <History ticketNumber={ticket.TicketNumber || ''} />
          </CategoryBox>
        </Grid>
      )}
    </Grid>
  );
}

DesktopView.propTypes = {}

export default DesktopView;
