import { createClient, errorExchange, fetchExchange, gql } from '@urql/core';
import { devtoolsExchange } from "@urql/devtools";
import { offlineExchange } from '@urql/exchange-graphcache';
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import { simplePagination } from '@urql/exchange-graphcache/extras';
import { getIntrospectedSchema, minifyIntrospectionQuery } from '@urql/introspection';
import { getIntrospectionQuery } from 'graphql';
import moment from 'moment';
import { GRAPHQL_ENDPOINT } from "../constants";
import authExchange from './exchanges/authExchange';
import { readBlockedCabinsQuery, readBlockedVehiclesQuery, readOverruleVehiclesQuery } from "./GraphQLQueries";

/**
 * storage for offline data
 *
 * @type {DefaultStorage}
 */
const storage = makeDefaultStorage({
  idbName: 'dashboard-app-cache',
  maxAge: 1,
});

fetch(GRAPHQL_ENDPOINT, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({
    variables: {},
    query: getIntrospectionQuery({ descriptions: false }),
  }),
})
    .then(result => result.json())
    .then(({ data }) => {
      const minified = minifyIntrospectionQuery(getIntrospectedSchema(data));
      window.localStorage.setItem('schema', JSON.stringify(minified));
    });

/**
 * URQL offline cache
 */
const cache = offlineExchange({
  schema: JSON.parse(window.localStorage.getItem('schema')),
  keys: {
    AddOnComponentAnswer: (data) => data.ID,
    AddOnComponentOption: (data) => data.ID,
    AddOnComponent: (data) => data.ID,
    AddOn: (data) => data.ID,
    AddOnType: (data) => data.ID,
    BlockedBooking: (data) => data.ID,
    BlockedCabin: (data) => `${data.WagonID}-${data.ID}`,
    BlockedVehicle: (data) => data.ID,
    Booking: (data) => data.ID,
    CabinAvailability: (data) => data.ID,
    Cabin: (data) => data.ID,
    Customer: (data) => data.ID,
    CancelPriceItem: (data) => JSON.stringify(data),
    CreditNoteLineItem: (data) => JSON.stringify(data),
    CustomDiscount: () => null,
    EmailMessage: (data) => data.ID,
    EntityGroup: (data) => `${data.TicketNumber}##${data.BookingCollectionID}`,
    Entity: (data) => data.ID,
    ExtraCharge: (data) => data.ID,
    Feedback: (data) => data.ID,
    FeedbackAnswer: (data) => data.ID,
    FeedbackQuestion: (data) => data.ID,
    InvoiceItemPreview: (data) => data.ID,
    Member: (data) => data.ID,
    Membership: (data) => data.ID,
    MicroactionAddOn: (data) => data.ID,
    MicroactionBookingOption: (data) => data.Code,
    MicroactionEntityType: (data) => data.ID,
    MicroactionPriceCategory: (data) => data.ID,
    MicroactionVehicle: () => null,
    MicroactionVehicleTotalPrice: () => null,
    OverruleVehicle: (data) => data.ID,
    Payment: (data) => data.ID,
    PaymentLineItem: (data) => data.ID,
    Price: (data) => data.Amount,
    PriceCategory: () => null,
    Route: (data) => data.ID,
    Sales: (data) => data.ID,
    Station: (data) => data.ID,
    Tax: (data) => JSON.stringify(data),
    Ticket: (data) => data.TicketNumber,
    TicketHistory: (data) => data.ID,
    TrainOccupancy: (data) => JSON.stringify([data.TrainConnectionID, data.Code]),
    VehicleSlot: (data) => JSON.stringify(data),
    CancelPrice: (data) => JSON.stringify(data),
    TotalAndRefundPrice: () => null,
    TotalPrice: (data) => JSON.stringify(data),
    TrainConnection: (data) => data.ID,
    VehicleSlotAvailability: (data) => data.ID,
    VehicleSlotCategory: (data) => JSON.stringify([data.TrainConnectionID, data.CategoryKey]),
    WagonAvailability: (data) => data.TicketNumber,
    Wagon: (data) => data.TicketNumber,
  },
  resolvers: {
    Query: {
      readOneTicket: (parent, args, cache) => ({
        __typename: 'Ticket',
        TicketNumber: args.Ticketnumber,
      }),
      readOneTrainConnection: (parent, args, cache) => ({
        __typename: 'TrainConnection',
        ID: args.TrainConnectionID,
      }),
      readTrainConnections: (parent, args, cache, info) => {
        const { parentKey: entityKey, fieldName } = info;

        const allFields = cache.inspectFields(entityKey);
        const fieldInfos = allFields.filter(info => info.fieldName === fieldName);
        const size = fieldInfos.length;

        let tmpResult = {}

        for (let i = 0; i < size; i++) {
          const { fieldKey } = fieldInfos[i];

          cache.resolve(entityKey, fieldKey)?.forEach(link => {
            const created = cache.resolve(link, 'StartDate')
            tmpResult[`${created}-${link}`] = link
          })
        }

        let result = Object.keys(tmpResult)
          .sort()
          .reduce((obj, key) => ([ ...obj, tmpResult[key] ]), [])
          .reverse();

        if (args.SortDirection === 'ASC') {
          result = result.reverse()
        }

        return result
      },
      readLastTickets: simplePagination({offsetArgument: 'skip', limitArgument: 'limit', mergeMode: 'after'}),
    //   readLastTickets: (_parent, fieldArgs, cache, info) => {
    //     const { parentKey: entityKey, fieldName } = info;
    //
    //     const allFields = cache.inspectFields(entityKey);
    //     const fieldInfos = allFields.filter(info => info.fieldName === fieldName);
    //     const size = fieldInfos.length;
    //
    //     let tmpResult = {}
    //
    //     for (let i = 0; i < size; i++) {
    //       const { fieldKey, arguments: args } = fieldInfos[i];
    //
    //       const links = cache.resolve(entityKey, fieldKey)
    //
    //       links.forEach(link => {
    //         const created = cache.resolve(link, 'Created')
    //         tmpResult[`${created}-${link}`] = link
    //       })
    //     }
    //
    //     const result = Object.keys(tmpResult)
    //       .sort()
    //       .reduce((obj, key) => ([ ...obj, tmpResult[key] ]), [])
    //       .reverse();
    //
    //
    //     const hasCurrentPage = cache.resolve(entityKey, fieldName, fieldArgs);
    //     if (hasCurrentPage) {
    //       return result;
    //     } else if (!info.store.schema) {
    //       return undefined;
    //     } else {
    //       info.partial = true;
    //       return result;
    //     }
    //   }
    },
    TicketHistory: {
      Timestamp(parent, args, cache, info) {
        return moment(parent.Timestamp, 'YYYY-MM-DD HH:mm:ss');
      },
    },
    Ticket: {
      Checked(parent, args, cache, info) {
        return parent.Checked
          ? moment(parent.Checked, 'YYYY-MM-DD HH:mm:ss')
          : false;
      },
    },
    VehicleSlot: {
      Checked(parent, args, cache, info) {
        return parent.Checked
          ? moment(parent.Checked, 'YYYY-MM-DD HH:mm:ss')
          : false;
      },
    },
  },
  optimistic: {
    changeAddOnAnswer: (variables, cache, info) => {
      if (variables.AddOnAnswerID) {
        cache.writeFragment(
          gql`
            fragment _ on AddOnComponentAnswer {
              ID
              ComponentID
              AddOnID
              OptionID
              OptionTitle
            }
          `,
          {
            ID: variables.AddOnAnswerID,
            ComponentID: variables.ComponentID,
            AddOnID: variables.AddOnID,
            OptionID: variables.OptionID,
            OptionTitle: variables.OptionTitle,
          }
        );
      }
    },
    setWakeUpTime: (variables, cache, info) => ({
      __typename: 'Ticket',
      TicketNumber: variables.Ticketnumber,
      WakeUpTime: variables.Time,
    }),
    updateStaff: (variables, cache, info) => ({
      __typename: 'TrainConnection',
      ID: variables.TrainConnectionID,
      Member: variables.Member.map(item => ({
        __typename: 'Member',
        ID: item
      })),
    }),
    doBreakfast: (variables, cache, info) => {
      const checked = cache.resolve(
        { TicketNumber: variables.Ticketnumber, __typename: 'Ticket' },
        'BreakfastDone'
      );
      return {
        __typename: 'Ticket',
        TicketNumber: variables.Ticketnumber,
        BreakfastDone: !checked,
      };
    },
    validateTicket: (variables, cache, info) => {
      return {
        __typename: 'Ticket',
        TicketNumber: variables.Ticketnumber,
        ...(variables.Checked ? { MarkAsReleased: false } : {}),
        Checked: variables.Checked
          ? moment().format('YYYY-MM-DD HH:mm:ss')
          : null,
      };
    },
    // validateVehicleSlot: (variables, cache, info) => {
    //   return {
    //     __typename: 'VehicleSlot',
    //     ID: variables.ID,
    //     Checked: variables.Checked
    //       ? moment().format('YYYY-MM-DD HH:mm:ss')
    //       : null,
    //   };
    // },
    moveBookingCollection: (variables, cache, info) => {
      const bookingCollectionKeys = cache.resolve(`Ticket:${variables.TicketNumber}`, 'EntityGroups')
      return {
        __typename: 'Ticket',
        TicketNumber: variables.TicketNumber,
        EntityGroups: bookingCollectionKeys.map(key => {
          console.log('key', key, `EntityGroup:${variables.TicketNumber}##${variables.CollectionID}`)
          if (key.includes(`EntityGroup:${variables.TicketNumber}##${variables.CollectionID}`)) {
            console.log('FOUND')
            return {
              '__typename': 'EntityGroup',
              'TicketNumber': variables.TicketNumber,
              'BookingCollectionID': variables.CollectionID,
              CabinID: parseInt(variables.CabinID, 10),
              WagonID: parseInt(variables.WagonID, 10),
              IsUnplaced: false
            };
          } else {
            return {
              '__typename': 'EntityGroup',
              'TicketNumber': cache.resolve(key, 'TicketNumber'),
              'BookingCollectionID': cache.resolve(key, 'BookingCollectionID'),
              IsUnplaced: false
            };
          }
        })
      }
    },
    unplaceBookingCollection: (variables, cache, info) => {
      const bookingCollectionKeys = cache.resolve(`Ticket:${variables.TicketNumber}`, 'EntityGroups')
      return {
        __typename: 'Ticket',
        TicketNumber: variables.TicketNumber,
        EntityGroups: bookingCollectionKeys.map(key => {
          if (key.includes(`${variables.TicketNumber}##${variables.CollectionID}`)) {
            return {
              '__typename': 'EntityGroup',
              'TicketNumber': variables.TicketNumber,
              'BookingCollectionID': variables.CollectionID,
              IsUnplaced: true
            };
          } else {
            return {
              '__typename': 'EntityGroup',
              'TicketNumber': cache.resolve(key, 'TicketNumber'),
              'BookingCollectionID': cache.resolve(key, 'BookingCollectionID'),
            };
          }
        })
      }
    }
  },
  updates: {
    Mutation: {
      blockOneCabin(result, _args, cache, _info) {
        cache.updateQuery({ query: readBlockedCabinsQuery, variables: { TrainConnectionID: _args['TrainConnectionID'] } }, data => {
          if (result.blockOneCabin === null) {
            return data
          }
          return {
            readBlockedCabins: result.blockOneCabin
          };
        });
      },
      removeCabinBlocking(result, _args, cache, _info) {
        cache.updateQuery({ query: readBlockedCabinsQuery, variables: { TrainConnectionID: _args['TrainConnectionID'] } }, data => {
          if (result.removeCabinBlocking === null) {
            return data
          }
          return {
            readBlockedCabins: result.removeCabinBlocking
          };
        });
      },
      blockVehicles(result, _args, cache, _info) {
        cache.updateQuery({ query: readBlockedVehiclesQuery, variables: { TrainConnectionID: _args['TrainConnectionID'] } }, data => {
          if (result.blockVehicles === null) {
            return data
          }
          return {
            readBlockedVehicles: result.blockVehicles
          };
        });
      },
      removeVehicleBlocking(result, _args, cache, _info) {
        cache.updateQuery({ query: readBlockedVehiclesQuery, variables: { TrainConnectionID: _args['TrainConnectionID'] } }, data => {
          if (result.removeVehicleBlocking === null) {
            return data
          }
          return {
            readBlockedVehicles: result.removeVehicleBlocking
          };
        });
      },
      addOverruleVehicle(result, _args, cache, _info) {
        cache.updateQuery({ query: readOverruleVehiclesQuery, variables: { TrainConnectionID: _args['TrainConnectionID'] } }, data => {
          if (result.addOverruleVehicle === null) {
            return data
          }
          return {
            readOverruleVehicles: result.addOverruleVehicle
          };
        });
      },
      removeOverruleVehicle(result, _args, cache, _info) {
        cache.updateQuery({ query: readOverruleVehiclesQuery, variables: { TrainConnectionID: _args['TrainConnectionID'] } }, data => {
          if (result.removeOverruleVehicle === null) {
            return data
          }
          return {
            readOverruleVehicles: result.removeOverruleVehicle
          };
        });
      },
    },
  },
  storage,
});

const errorExch = errorExchange({
  onError: (error) => {
    console.log('error', error);
    // we only get an auth error here when the auth exchange had attempted to refresh auth and getting an auth error again for the second time
    const isAuthError = error.graphQLErrors.some(
      e => e.message.includes('User forced logout')
        || e.message.includes('Invalid token')
    );

    if (isAuthError && window.location !== '/logout/' && window.location !== '/login/') {
      console.log(error.message)

      const event = new CustomEvent('logoutUser')
      document.dispatchEvent(event)
    }
  }
});

/**
 * URQL Client for silverstripe
 *
 * @returns {*}
 */
const GraphQLClientSilverstripe = createClient({
  url: GRAPHQL_ENDPOINT,
  exchanges: [
    devtoolsExchange,
    cache,
    authExchange,
    errorExch,
    fetchExchange
  ],
  requestPolicy: 'cache-and-network',
  fetchOptions: () => ({
    headers: {
      "x-socket-id": sessionStorage.getItem('socket-id')
    },
  }),
});

// initWebsocket(GraphQLClientSilverstripe)

export default GraphQLClientSilverstripe;
