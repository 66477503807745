import makeStyles from '@mui/styles/makeStyles';

/**
 * Material UI Styles for Layout feature
 */
// const defaultTheme = createTheme();
export const useStyles = makeStyles(() => ({
  drawer: {
    position: 'relative',
    zIndex: 1110,
    width: 130
  },
  link: {
    display: 'block',
    width: '100%',
    textDecoration: 'none',
    '&.active > div': {
      background: '#003B61'
    }
  },
  logoLink: {
    display: 'block',
    width: '100%',
    textDecoration: 'none',
  },
  logoImage: {
    '@media (min-width:1200px)': {
      maxWidth: '100%',
      filter: 'brightness(0) invert(1)'
    }
  },
  button: {
    flexDirection: 'column',
    borderRadius: '16px',
    '&:hover': {
      backgroundColor: 'rgba(0,59,97,0.5)'
    },
    '&:focus': {
      backgroundColor: 'rgba(0,59,97,0.5)'
    },
    '&:active': {
      backgroundColor: 'rgba(0,59,97,0.5)'
    },
    '&.Mui-selected': {
      backgroundColor: '#003B61'
    }
  },
  icon: {
    color: '#fff',
    '& .MuiSvgIcon-root': {
      margin: '0 auto'
    }
  },
  text: {
    '& span': {
      fontSize: 14,
      color: '#fff',
      textAlign: 'center'
    }
  }
}));
