import { authExchange } from '@urql/exchange-auth';
import { refreshTokenMutation } from "../GraphQLQueries";
import { CLIENT_ID } from '../OAuth2';

async function initializeAuthState() {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  return { token, refreshToken };
}

const exchange = authExchange(async utils => {
  let token = localStorage.getItem('token');
  let refreshToken = localStorage.getItem('refreshToken');

  return {
    addAuthToOperation(operation) {
      if (token !== localStorage.getItem('token') || refreshToken !== localStorage.getItem('refreshToken')) {
        token = localStorage.getItem('token');
        refreshToken = localStorage.getItem('refreshToken');
      }

      if (!token || !refreshToken) {
        token = localStorage.getItem('token');
        refreshToken = localStorage.getItem('refreshToken');

        if (!token) {
          return operation;
        }
      }
      return utils.appendHeaders(operation, {
        Authorization: `Bearer ${token}`,
      });
    },
    didAuthError(error, _operation) {
      return error.graphQLErrors.some(
        e => e.message.includes('The resource owner or authorization server denied the request')
      )
    },
    async refreshAuth() {
      const data = await initializeAuthState();

      if (data.refreshToken) {
        await utils.mutate(refreshTokenMutation, {
          refresh_token: data.refreshToken,
          grant_type: 'refresh_token',
          client_id: CLIENT_ID
        }).then(result => {
          token = result.data.refreshToken?.token;
          refreshToken = result.data.refreshToken?.refresh_token;

          if (result.data?.refreshToken) {
            // Update our local variables and write to our storage
            token = result.data.refreshToken.token;
            refreshToken = result.data.refreshToken.refresh_token;

            const time = Math.round(new Date().getTime() / 1000)
            const expiredTimestamp = time + parseInt(result.data.refreshToken.expires_in, 10)

            // save the new tokens in storage for next restart
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('expiredTimestamp', expiredTimestamp.toString());
          } else {
            token = null
            refreshToken = null
          }
        })
      }
    },
    willAuthError: (operation) => {
      // e.g. check for expiration, existence of auth etc
      const currTime = Math.round(new Date().getTime() / 1000)
      const tokenEndOfLife = localStorage.getItem('expiredTimestamp')

      if (
        operation.kind === 'mutation' &&
        // Here we find any mutation definition with the "login" field
        operation.query.definitions.some(definition => {
          return (
            definition.kind === 'OperationDefinition' &&
            definition.selectionSet.selections.some(node => {
              // The field name is just an example, since signup may also be an exception
              return node.kind === 'Field' && ['login', 'logout'].includes(node.name.value);
            })
          );
        })
      ) {
        return false;
      } else {
        return currTime > tokenEndOfLife;
      }
    },
  };
});

export default exchange
