import PropTypes from 'prop-types';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo, useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import { Member } from "../../../gql/graphql";

import { logoutMutation, readMyselfQuery } from '../../lib/GraphQLQueries'
import { useNotificationPush } from "../Snackbar/SnackbarContext";

type LoginContextType = {
  isLoggedIn: boolean,
  setLoggedIn: Dispatch<SetStateAction<boolean>>
}

type MemberContextType = {
  member: Member | null
  reloadMember: () => void
}

type PermissionContextType = {
  canAccess: (code: string) => boolean
}

const LoginContext = createContext<LoginContextType | null>(null);
const MemberContext = createContext<MemberContextType>({member: null, reloadMember: () => {}});
const PermissionContext = createContext<PermissionContextType | null>(null);

export const useLogin = () => useContext(LoginContext)
export const useMember = () => useContext(MemberContext)
export const usePermissions = () => useContext(PermissionContext)

const LoginContextProvider = ({ children }: {children: React.ReactNode}) => {
  const { pushSuccess } = useNotificationPush()
  const navigate = useNavigate()
  const location = useLocation()
  const logoutRef = useRef(false)
  const [isLoggedIn, setLoggedIn] = useState(() => {
    return typeof localStorage.getItem('token') !== 'object' && typeof localStorage.getItem('refreshToken') !== 'object'
  })

  const [, logout] = useMutation(logoutMutation)

  const handleLogout = useCallback(() => {
    logout({}).finally(() => {
      setLoggedIn(false)
      indexedDB.deleteDatabase('dashboard-app-cache')
      // localStorage.clear()
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('expiredTimestamp')
      pushSuccess('Die Sitzung wurde beendet')
    })
  }, [logout, pushSuccess])

  useEffect(() => {
    if (!isLoggedIn && logoutRef.current) {
      logoutRef.current = false
      handleLogout()
    } else {
      logoutRef.current = true
    }
  }, [handleLogout, isLoggedIn, logout, pushSuccess])

  useEffect(() => {
    const listener = () => {
      if (logoutRef.current) {
        handleLogout()
      }
    }

    document.addEventListener('logoutUser', listener)
    return () => document.removeEventListener('logoutUser', listener)
  }, [handleLogout])

  const [member, reloadMember] = useQuery({
    query: readMyselfQuery,
    variables: {},
    pause: !isLoggedIn
  })

  const canAccess = useCallback((code: string | string[]) => {
    const currentMember = member.data?.readMyself

    if (currentMember && currentMember.Permissions) {
      if (currentMember.Permissions.findIndex(permission => permission && permission === 'ADMIN') >= 0) {
        return true
      }

      return typeof code === 'object'
        ? currentMember.Permissions.findIndex(permission => permission && code.includes(permission)) >= 0
        : currentMember.Permissions.findIndex(permission => permission && permission === code) >= 0
    }
    return false
  }, [member])

  const debugMessage = useMemo(() => member.data?.readMyself?.IsDebugMember
      ? (
          <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '20px',
            background: 'red',
            textAlign: 'center',
            color: 'white',
            letterSpacing: '2px',
            fontSize: '14px',
            fontWeight: 'bold'
          }}>
            DEBUG MODE
          </div>
      ) : null
  , [member])

  useEffect(() => {
    if (!isLoggedIn) {
      if (!location.pathname.startsWith('/password-reset') && !location.pathname.startsWith('/login')) {
        navigate('/login/')
      }
    }
  }, [isLoggedIn, navigate, location])

  return (
    <LoginContext.Provider value={{ isLoggedIn, setLoggedIn }}>
      <MemberContext.Provider value={{ member: (member.data?.readMyself || null), reloadMember }}>
        <PermissionContext.Provider value={{ canAccess }}>
          {debugMessage}
          {children}
        </PermissionContext.Provider>
      </MemberContext.Provider>
    </LoginContext.Provider>
  );
}

LoginContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default LoginContextProvider
