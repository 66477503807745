import { Box, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'urql';
import { readOneTicketQuery } from '../../../lib/GraphQLQueries'
import { useTicketNumberUpdate } from '../../TicketDetails/TicketDetailsContext'
import TicketNumberChip from "../../TicketNumberChip";
import { useStyles } from './../styles'

const TicketItem = ({ ticket: ticketParam }) => {
  const [ticketNumber, setTicketNumber] = useState('')
  const updateTicketNumber = useTicketNumberUpdate()
  const classes = useStyles()

  const [ticketQuery] = useQuery({
    query: readOneTicketQuery,
    pause: !ticketNumber,
    variables: {
      Ticketnumber: ticketNumber
    },
    requestPolicy: 'cache-first'
  })

  const bookingCollections = useMemo(() => {
    return JSON.parse(ticketParam?.BookingCollections || '[]')
  }, [ticketParam])

  const ticketResult = useMemo(() => {
    return ticketNumber ? ticketQuery.data?.readOneTicket : ticketParam
  }, [ticketNumber, ticketParam, ticketQuery.data?.readOneTicket])

  const wagonSummary = useMemo(() => {
    return ticketResult.WagonSummary
      ? [ticketResult.WagonSummary]
      : bookingCollections.reduce((acc, item) => {
        if (acc.includes(item.WagonNumber)) {
          return acc
        }

        return [...acc, item.WagonNumber]
      }, [])
  }, [bookingCollections, ticketResult.WagonSummary])

  const entitySummary = useMemo(() => {
    return ticketResult.EntitySummary
      ? [ticketResult.EntitySummary]
      : bookingCollections.reduce((acc, item) => {
        return [...acc, item.EntitySummary]
      }, [])
  }, [bookingCollections, ticketResult.EntitySummary])

  if (typeof ticketParam === 'string' && ticketNumber !== ticketParam) {
    setTicketNumber(ticketParam)
    return null
  }

  if (!ticketResult) {
    return null
  }

  return (
    <Box className={classes.item} onClick={() => updateTicketNumber(ticketResult.TicketNumber)}>
      <Box className={classes.buttonInner}>
        {/*<Typography component={'h3'} variant={'h5'}>{ticketResult.TicketNumber}</Typography>*/}
        <Box sx={{marginLeft: -1}}>
          <TicketNumberChip ticket={ticketResult} />
        </Box>
        {ticketResult.CanView && (
          <Box className={classes.details}>
            <Typography
              component={'span'}
              className={classes.wagon}
            >
              {ticketResult.Customer
                ? `${ticketResult.Customer.Firstname} ${ticketResult.Customer.Lastname}, ${moment(ticketResult.StartDate).format('DD.MM.YYYY')}`
                : `${ticketResult.Name}, ${moment(ticketResult.StartDate).format('DD.MM.YYYY')}`
              }<br />
              {/*{`${ticketResult.DepartureStation} - ${ticketResult.ArrivalStation}`}*/}
            </Typography>
          </Box>
        )}
        {wagonSummary &&
          <Box>
            <Typography component={'span'}
                        className={classes.wagon}>Wagen {wagonSummary.join(' / ')}</Typography>
            <Typography component={'span'} className={classes.entity}>
              &nbsp;&nbsp;/&nbsp;&nbsp;Platz {entitySummary.join(' / ')}
            </Typography>
          </Box>
        }
      </Box>
      {/*<Box className={classes.icon}>*/}
      {/*  { ticketResult.Checked ? <Grid container className={classes.checkedButton}><DoneIcon /></Grid > : ''}*/}
      {/*</Box>*/}
    </Box>
  );
}

TicketItem.propTypes = {
  ticket: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}

export default TicketItem;
