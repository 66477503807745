import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const TicketNumberContext = createContext();
const TicketNumberUpdateContext = createContext();

export const useTicketNumber = () => useContext(TicketNumberContext)
export const useTicketNumberUpdate = () => useContext(TicketNumberUpdateContext)

const TicketDetailsContext = ({ children }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString)
  const ticketPreset = urlParams.get('ticket') || ''

  const [ticketNumber, setTicketNumber] = useState(ticketPreset)

  const handleChange = (tn) => {
    setTicketNumber(tn)
  }

  return (
    <TicketNumberContext.Provider value={ticketNumber}>
      <TicketNumberUpdateContext.Provider value={handleChange}>
        {children}
      </TicketNumberUpdateContext.Provider>
    </TicketNumberContext.Provider>
  );
}

TicketDetailsContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default TicketDetailsContext
